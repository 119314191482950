import React from 'react'
import "./Home.css"
import { Quality, Hero, Products } from '../../features/Home/index'
import { Categories } from '../../components'

const Home = ({ options, stylesData, contentData, url, innerPageData, translationsData, setSelectedCategoryId, selectedCategoryId, apiVersion }) => {
  return (
    <div>
        <Hero 
          contentData={contentData} 
          url={url} 
          innerPageData={innerPageData} 
          stylesData={stylesData}
        />
        <Quality 
          contentData={contentData} 
        />
        <Categories 
          contentData={contentData} 
          url={url} translationsData={translationsData} 
          setSelectedCategoryId={setSelectedCategoryId} 
        />
        <Products 
          contentData={contentData} 
          url={url} 
          translationsData={translationsData}  
          selectedCategoryId={selectedCategoryId}
          apiVersion={apiVersion} 
          options={options}
        />
    </div>
  )
}

export default Home