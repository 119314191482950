import React from 'react'
import { Herosection } from '../../components/index'
import { Contact } from '../../features/ContactUs/index'

const ContactUs = ({ innerPageData, url, contentData, stylesData }) => {
  return (
    <div>
        <Herosection bg={url + innerPageData.image} title={innerPageData.name}/>
        <Contact 
        contentData={contentData} 
        url={url} 
        stylesData={stylesData}
        />
    </div>
  )
}

export default ContactUs