import React from 'react'
import "./FloatBtn.css"
import whatsapp from "../../assets/images/vectors/whatsapp.svg"

const FloatBtn = () => {
    return (
        <a href="https://wa.me/00963944009988" target="_blank" className='float-btn'>
            <img src={whatsapp} className='float-btn-img' />
        </a>
    )
}

export default FloatBtn
