import React from 'react'
import "./About.css"
import { Container,Col, Row } from 'react-bootstrap'

const About = ({ contentData, stylesData, url }) => {
  return (
    <div className='about'>
        <img id="about-top" src={url + stylesData.style_background_blue_big} />
        <Container>
          <Row className='d-flex justify-content-center'>
            <Col sm={10} className='about-content'>
              <h1>{contentData.about_us_title}</h1>
              <p dangerouslySetInnerHTML={{ __html: contentData.about_us_description }}></p>
              <img data-aos="fade-up" data-aos-duration="800" data-aos-offset="500" src={url + contentData.about_us_image} />
            </Col>
          </Row>
        </Container>
        <img id='about-bottom' src={url + stylesData.style_background_blue} />
    </div>
  )
}

export default About