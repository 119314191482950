import React from 'react'
import "./Agents.css"
import { Agent } from "../../features/Agents"
import { Col, Container, Row } from 'react-bootstrap'

const Agents = ({ contentData, translationsData }) => {

    const agentsData = contentData.agents || [];
    const syrianAgentsData = contentData.syria_agents || [];

    return (
        <div className='agents'>
            <Container>
                <h1 className='agents-title'>{translationsData.haseeb_agent_information}</h1>
                <Row>
                    {
                        agentsData.map((item, index) => (
                            <Col md={6} key={index}>
                                <Agent
                                    title={item.name}
                                    para={item.information.map((info, index) => (
                                        <p key={index}>{info.value}</p>
                                    ))}
                                />
                            </Col>
                    ))}
                    
                </Row>
            </Container>
            <h1 className='agents-title'>{translationsData.agents_in_syria}</h1>
            <Container>
                <Row>
                    {
                        syrianAgentsData.map((item, index) => (
                            <Col md={6} key={index}>
                                <Agent
                                    title={item.name}
                                    para={item.information.map((info, index) => (
                                        <p key={index}>{info.value}</p>
                                    ))}
                                />
                            </Col>
                    ))}
                </Row>
                {/*<Row>
                     <table className='branches'>
                        <tr>
                            <td id='first-column'></td>
                            <td className='fill'>الفرع:</td>
                            <td className='fill'>العنوان التفصيلي:</td>
                            <td className='fill'>خدمة الطحن المباشر:</td>
                            <td className='fill'>رقم الهاتف:</td>
                        </tr>
                        <tr>
                            <td className='fill'>1</td>
                            <td>فرع الشعلان</td>
                            <td>دمشق - الشعلان – بجانب مطعم الوجبة السريعة</td>
                            <td>موجود</td>
                            <td>3335314</td>
                        </tr>
                        <tr>
                            <td className='fill'>2</td>
                            <td>فرع الخطيب</td>
                            <td>دمشق – الخطيب – بعد كازية القصور – باتجاه ساحة الخطيب</td>
                            <td>غير موجود</td>
                            <td>4418109</td>
                        </tr>
                        <tr>
                            <td className='fill'>3</td>
                            <td>فرع المزة</td>
                            <td>دمشق – المزة – شيخ سعد – مقابل فرن الشيخ السعد </td>
                            <td>موجود</td>
                            <td>6616615</td>
                        </tr>
                        <tr>
                            <td className='fill'>4</td>
                            <td>فرع جرمانا</td>
                            <td>ريف دمشق – جرمانا – ساحة السيوف – مدخل الجناين</td>
                            <td>غير موجود</td>
                            <td>5633344</td>
                        </tr>
                        <tr>
                            <td className='fill'>5</td>
                            <td>فرع دويلعة</td>
                            <td>دمشق – دويلعة – مقابل مأكولات الشام</td>
                            <td>موجود</td>
                            <td>4727611</td>
                        </tr>
                    </table>
                </Row> */}
            </Container>
        </div>
    )
}

export default Agents
