import React from 'react'
import "./Supermarket.css"

const Supermarket = () => {
    return (
        <div className='supermarket' data-aos="fade-right" data-aos-duration="1500">
            No Data
        </div>
    )
}

export default Supermarket
