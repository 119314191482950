import React from 'react'
import "./Details.css"
import { Col, Container, Row } from 'react-bootstrap'

const Details = ({ contentData, translationsData }) => {

    const productData = contentData.product || {};
    const specificationsData = contentData?.product?.specifications || [];

  return (
    <div>
            <Container className={(productData.description && productData.description.length > 0) ? "" : "d-none"}>
                <div 
                    className='details-para' 
                    dangerouslySetInnerHTML={{ __html: productData.description }}>
                </div>
            </Container>
                
           <Container className={( specificationsData.length > 0) ? "" : "d-none"}>
                <Row className='align-items-start'>
                    <Col md={6} className={(productData.ingredients && productData.ingredients.length > 0) ? "details-desc" : "d-none"}>
                            <h1>INGREDIENTES</h1>
                            <p dangerouslySetInnerHTML={{ __html: productData.ingredients }}></p>
                    </Col>
                    <Col md={6} className='details-info'>
                    {specificationsData && specificationsData.length > 0 && (
                        <>
                        <h1>{translationsData.specification}</h1>
                        {specificationsData.map((item, index) => (
                            <div className='details-info-content' key={index}>
                                <h2>{item.specification.specification}</h2>
                                <h3>{item.value}</h3>
                            </div>
                        ))}
                        </>
                    )}
                    </Col>
                </Row>
            </Container>
    </div>
  )
}

export default Details