import React from 'react'
import "./Category.css"

const Category = ( { title1, title2, vid } ) => {
  return (
    <div className='category' data-aos="fade-up" data-aos-duration="1500">
      <a href='#products'>
        <video autoPlay muted loop src={vid}></video>
        <div className='category-content'>
            <h1 className='category-title'>{title1}</h1>
            <h1 className='category-title'>{title2}</h1>
        </div>
      </a>
    </div>
  )
}

export default Category