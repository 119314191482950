import React from 'react'
import "./ProductDetails.css"
import { Hero, Details, Products } from '../../features/ProductDetails/index'

const ProductDetails = ({ apiVersion, url, contentData, translationsData, stylesData }) => {
  return (
    <div className='productdetails'>
        <Hero
          url={url}
          contentData={contentData}
          stylesData={stylesData}
        />
        <Details
          contentData={contentData}
          translationsData={translationsData}
        />
        <Products
          url={url}
          contentData={contentData}
          apiVersion={apiVersion}
          translationsData={translationsData}
        />
    </div>
  )
}

export default ProductDetails