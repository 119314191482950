import React from 'react'
import "./Products.css"
import { Herosection } from '../../components/index'
import { OtherProducts } from '../../features/Products/index'

const Products = ({ innerPageData, contentData, url, stylesData }) => {
  return (
    <div>
        <Herosection title={innerPageData.name} bg={url + innerPageData.image} />
        <OtherProducts
          contentData={contentData}
          url={url}
          stylesData={stylesData}
        />
    </div>
  )
}

export default Products