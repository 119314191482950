import React, { useState, useEffect } from 'react';
import "./Navpages.css";
import Cookies from 'js-cookie';
import { Container, Nav, Navbar, NavDropdown, Dropdown } from 'react-bootstrap';
import earth from "../../assets/images/vectors/earth.svg"

const Navpages = ({ navbarData, url, logos, stylesData, selectedLanguage, setSelectedLanguage }) => {
  const [isActive, setIsActive] = useState(true);

  const handleClick = () => {
    setIsActive(current => !current);
  };

  const renderNavItem = (item) => {
    if (item.children && item.children.length > 0) {
      return (
        <NavDropdown
          className="nav-drop"
          title={item.name}
          id={`nav-dropdown-${item.id}`}
          key={item.id}
        >
          {item.children.map((child) => (
            <NavDropdown.Item key={child.id} href={child.url}>
              {child.name}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      );
    } else {
      return (
        <Nav.Link
          className="navbar-link"
          key={item.id}
          href={`/${item.url}`}
        >
          {item.name}
        </Nav.Link>
      );
    }
  };

  const renderNavLinks = (items) => {
    const firstThreeItems = items.slice(0, 3);
    const remainingItems = items.slice(3);

    return (
      <>
        <Nav className="">{firstThreeItems.map((item) => renderNavItem(item))}</Nav>
        {remainingItems.length > 0 && (
          <Nav>
            {remainingItems.map((item) => renderNavItem(item))}
            <Dropdown className='nav-language'>
              <Dropdown.Toggle id="" >
                {selectedLanguage}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleLanguageChange('en')}>EN</Dropdown.Item>
                <Dropdown.Item onClick={() => handleLanguageChange('ar')}>AR</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        )}
      </>
    );
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    // Store selected language in cookies
    Cookies.set('selectedLanguage', language);
    // Reload the page
    window.location.reload();
  };

  useEffect(() => {
    // Read selected language from cookies when component mounts
    const selectedLanguageValue = Cookies.get('selectedLanguage');
    if (selectedLanguageValue) {
      setSelectedLanguage(selectedLanguageValue);
    }
  }, []);

  return (
    <>
      <div className="nav-content-container" style={{ height: isActive ? "unset" : "100%"}}></div>
      <Navbar 
        expand="lg" 
        className="navpages"
        style={{ background: `url(${url + stylesData.style_background_navbar}) no-repeat` }}
      >
        <Container className="navbar-container">
          <Navbar.Brand href="/" className="navlogo">
            <img src={url + logos.logo} alt="Logo" />
          </Navbar.Brand>

          <Navbar.Toggle onClick={handleClick} />

          <div className="nav-content" style={{ right: isActive ? "-200%" : "0" }}>
            <div className="nav-content-btn">
              <button className="close-button" onClick={handleClick}></button>
            </div>
            {renderNavLinks(navbarData)}
            
            <div className="nav-content-img">
              <img src={require("../../assets/images/products/gray.png")} alt="Product" />
            </div>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default Navpages;