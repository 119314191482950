import React from 'react'
import "./Hero.css"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { Col, Row } from 'react-bootstrap'

const Hero = ({ contentData, url, innerPageData, stylesData }) => {

  const gallery = innerPageData.gallery || [];
  const productData = contentData.homepage_product_showcase || {};

  return (
    <div className='hero'>
        <img id='coffe' src={url + contentData.homepage_coffee_beans_image} data-aos="fade-down" data-aos-duration="1800" />
        <img id='group320' src={url + stylesData.style_background_white} />
        <img id='group309' src={url + stylesData.style_background_white} />
        <Row className='hero-content'>
            <Col md={6} className='hero-first'
            style={{ background: `${productData.background_color}`}}
            >
                <img src={require("../../../../assets/images/pattern.png")} />
                <div className='hero-first-content'>
                    <h1>{productData.name}</h1>
                    <h2>{productData.sub_title}</h2>
                    <img src={url + productData.image} data-aos="fade-right" data-aos-duration="1800" />
                </div>
            </Col>
            <Col md={6} className='hero-second'>
                <Splide
                    options = {{
                      rewind: true,
                      arrows: false,
                      pagination: true,
                      autoplay: true,
                      type: "loop",
                    
                    }}>
                      {
                        gallery.map((item, index) => (
                          <SplideSlide key={index}>
                            <img src={url + item.image} />
                          </SplideSlide>
                        ))
                      }
                </Splide>
             
            </Col>
        </Row>
        <div className='hero-responsive'>
            <div className='hero-first-content'>
                <h1>{productData.name}</h1>
                <h2>{productData.sub_title}</h2>
                <img src={url + productData.image} data-aos="fade-right" data-aos-duration="1800" />
            </div>
            <div className='hero-second'>
                <Splide
                    options = {{
                      rewind: true,
                      arrows: false,
                      pagination: true,
                      autoplay: true,
                      type: "loop",
                    
                    }}>
                    {
                      gallery.map((item, index) => (
                        <SplideSlide key={index}>
                          <img src={url + item.image} />
                        </SplideSlide>
                      ))
                    }
                </Splide>
            </div>
        </div>
    </div>
  )
}

export default Hero