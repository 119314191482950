import React from 'react';
import "./Quality.css";
import { Container } from 'react-bootstrap';

const Quality = ({ contentData }) => {
  return (
    <Container>
      <div className='quality' dangerouslySetInnerHTML={{ __html: contentData.homepage_description }}></div>
    </Container>
  );
}

export default Quality;