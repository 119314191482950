import React from 'react'
import "./Category.css"
import { Herosection } from '../../components/index'
import { CategoryContent } from '../../features/Category/index'


const Category = ({ contentData, url, stylesData, innerPageData, apiVersion, options, translationsData }) => {
  return (
    <div> 
        <Herosection bg={url + innerPageData.image} title={innerPageData.name}/>
        <CategoryContent
          url={url}
          contentData={contentData}
          stylesData={stylesData}
          apiVersion={apiVersion}
          options={options}
          translationsData={translationsData}
        />
    </div>
  )
}

export default Category