import React from 'react'
import "./Ingredients.css"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { Container, Row, Col } from 'react-bootstrap'

const Ingredients = ({ url, contentData }) => {

  const productData = contentData.product_slider || [];
  
  return (
    <div className='ingredients'>
        <img id="ingredients-top" src={require("../../../../assets/images/vectors/Group 309.png")} />
        <Container>
            <div className='ingredients-content'>
                {/* <p 
                  className="ingredients-content-para" 
                  dangerouslySetInnerHTML={{ __html: contentData.about_us_4_description_1 }}
                  data-aos="fade-up"
                  data-aos-duration="1500"  
                >
                </p> */}
                <Row className='ingredients-slider'>
                  <Col lg={5}>
                    <Splide
                        options = {{
                          rewind: true,
                          arrows: false,
                          pagination: false,
                          autoplay: true,
                          type: "loop",
                       
                        }}>
                          {
                            productData.map((item, index) => (
                              <SplideSlide>
                                <div className='ingredients-slide'>
                                  <h1>{item.name}</h1>
                                  <img src={url + item.image} />
                                </div>
                              </SplideSlide>
                            ))
                          }
                        
                    </Splide>
                  </Col>
                  <Col lg={7}>
                     <p 
                     dangerouslySetInnerHTML={{ __html: contentData.about_us_4_description_2}}
                     data-aos="fade-left"
                     data-aos-duration="1500"
                     >
                     </p>
                  </Col>
                </Row>
            </div>
        </Container>
    </div>
  )
}

export default Ingredients