import React from 'react'
import "./Categories.css"
import { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Category from '../Category/Category'

const Categories = ({ contentData, url, translationsData, setSelectedCategoryId }) => {
    const categories = contentData.categories || [];
    const handleCategoryClick = (categoryId) => {
        setSelectedCategoryId(categoryId);
    };
   
  return (
    <>
        <div className='categories' style={{  background:  `url(${url + contentData.our_categories_background}) no-repeat`, }}>
            <img id='quality1' src={ url + contentData.our_categories_left_image } />
            <img id='quality2' src={ url + contentData.our_categories_right_image } />
            <Container className='categories-container'>
                <h1>{translationsData.our_categories}</h1>
                <div className='categories-content'>
                    <Row>
            
                        {
                            categories.map((item,index) => (
                                <Col key={index} md={4} className='category-container'  onClick={() => handleCategoryClick(item.id)}>
                                    <img src={url + contentData.our_categories_center_image} />
                                    <img src={url + contentData.our_categories_center_image} />
                                    <Category 
                                        title1={item.name.split(" ")[0]} 
                                        title2={item.name.split(" ")[1]}
                                        vid={url + item.video}
                                     />
                                </Col>
                            ))
                        }
                    </Row>
                
                </div>
            </Container>
        </div>
    </>
  )
}

export default Categories