import React from 'react'
import "./Product.css"
import { Container } from 'react-bootstrap'

const Product = ({ url, contentData, stylesData }) => {
  return (
    <div className='prodcut-about'>
        <Container>
            <div className='product-about-content'>
                <h1 dangerouslySetInnerHTML={{ __html: contentData.about_us_2_description }}></h1>
                <img src={url + contentData.about_us_2_image } />
            </div>
        </Container>
        <img id="product-bottom" src={url + stylesData.style_background_white} />
    </div>
  )
}

export default Product