import React from 'react'
import "./Hero.css"
import { Row, Col } from 'react-bootstrap'

const Hero = ({ contentData, url, stylesData }) => {

  const productData = contentData.product || {}; 

  return (
    <Row className='hero-details'>
        <Col 
          md={6} 
          className='hero-details-content'
          style={{ background: `${productData.background_color}`}}
          > 
          <h1>{productData.name}</h1>
          <div className='hero-details-product'>
              <img src={url + productData.image} />
          </div>
        </Col>
        <Col 
          md={6} 
          className='hero-details-img'
          style={{ background: `url(${productData.product_details_image ? url + productData.product_details_image : "/static/media/coffedetails.0a821e1c79d7289a5db7.jpg" }) no-repeat` }} 
        >
  
        </Col>
        <img id='hero-details-footer' src={url + stylesData.style_background_white} />
    </Row>
  )
}

export default Hero