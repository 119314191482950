import React from 'react'
import "./Contact.css"
import { Container, Col, Row } from 'react-bootstrap'

const Contact = ({ contentData, url, stylesData }) => {

    const socialmediaData = contentData.social || [];

  return (
    <div className='contact'>
        <img id="contact-up" src={url + stylesData.style_background_white} />
        <Container className="contact-content">
            <h1 className='contact-title' dangerouslySetInnerHTML={{ __html: contentData.contact_us_description }}></h1>
            <Row className='contact-btns'>
                <Col md={6}>
                    <div className="contact-btn" data-aos="fade-right" data-aos-duration="1500">
                        <h1 className="contact-btn-title">{contentData.contact_us_1_title}</h1>
                        <a className="contact-btn-link" href='#'>{contentData.contact_us_1_value}</a>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="contact-btn" data-aos="fade-left" data-aos-duration="1500" >
                        <h1 className="contact-btn-title">{contentData.contact_us_2_title}</h1>
                        <a className="contact-btn-link" href='#'>{contentData.contact_us_2_value}</a>
                    </div>
                </Col>

                <Col md={6}>
                    <div className="contact-btn" data-aos="fade-left" data-aos-duration="1500" >
                        <h1 className="contact-btn-title">Whatsapp</h1>
                        <a className="contact-btn-link" href='#'>00963944009988 </a>
                    </div>
                </Col>

                <Col md={6}>
                    <div className="contact-btn" data-aos="fade-left" data-aos-duration="1500" >
                        <h1 className="contact-btn-title">Address</h1>
                        <a className="contact-btn-link" href='#'>سوريا – دمشق – ابن عساكر - المنطقة الصناعية – ج.10</a>
                    </div>
                </Col>
            </Row>
            <div className='contact-icons'>
                {
                    socialmediaData.map((item, index) => (
                        <div data-aos="zoom-in" data-aos-duration="1500">
                            <a 
                                className='contact-icon' 
                                href={`${item.url}`} 
                                target="_blank">
                                <img src={url + item.image} />
                            </a>
                        </div>
                        
                    ))
                }
                {/* <div className='contact-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="16" height="16" class="bi bi-instagram" viewBox="0 0 16 16">
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                    </svg>
                </div>
                <div className='contact-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff"  viewBox="0 0 50 50" width="50px" height="50px">
                        <path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"/>
                    </svg>
                </div>
                <div className='contact-icon'>
                    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 8 19">
                        <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd"/>
                    </svg>
                </div> */}
            </div>
        </Container>
    </div>
  )
}

export default Contact