import React from 'react'
import "./JoinOurTeam.css"
import { Container, Row, Col } from 'react-bootstrap'

const JoinOurTeam = () => {
    return (
        <Container>
            <form className='container-form'>
                <Row>
                    <Col md={4} className="form-text">
                        <h1 className='form-title'><span>*</span>Compnay Name</h1>
                        <input type='text' placeholder='Company Name' className='form-input' />
                    </Col>
                    <Col md={4} className="form-text">
                        <h1 className='form-title'><span>*</span>Email</h1>
                        <input type='email' placeholder='Email' className='form-input' />
                    </Col>
                    <Col md={4} className="form-text">
                        <h1 className='form-title'><span>*</span>Full Name</h1>
                        <input type='text' placeholder='Full Name' className='form-input' />
                    </Col>
                    <Col md={4} className="form-text">
                        <h1 className='form-title'><span>*</span>Mobile</h1>
                        <input type='text' placeholder='Ex : 09 123 456 78' className='form-input' />
                    </Col>
                    <Col md={4} className="form-text">
                        <h1 className='form-title'><span>*</span>Nationality</h1>
                        <input type='text' placeholder='Nationality' className='form-input' />
                    </Col>
                    <Col md={4} className="form-text">
                        <h1 className='form-title'><span>*</span>Ready to join us</h1>
                        <input type='date' className='form-input' />
                    </Col>
                    <Col md={6} className="form-text">
                        <h1 className='form-title'><span>*</span>Address</h1>
                        <input type='date' placeholder='Address' className='form-input' />
                    </Col>
                    <Col md={6} className="form-text">
                        <h1 className='form-title'><span>*</span>Upload Your CV</h1>
                        <input type='file' className='form-input' />
                    </Col>
                    <Col md={3} className="form-text">
                        <h1 className='form-title'><span>*</span>Gender</h1>
                        <select className='form-input'>
                            <option>Male</option>
                            <option>Female</option>
                        </select>
                    </Col>
                    <Col md={3} className="form-text">
                        <h1 className='form-title'><span>*</span>Marital status</h1>
                        <select className='form-input'>
                            <option>Single</option>
                            <option>Married</option>
                        </select>
                    </Col>
                    <Col md={3} className="form-text">
                        <h1 className='form-title'><span>*</span>Education level</h1>
                        <select className='form-input'>
                            <option>Bachelor degree</option>
                            <option>High school</option>
                            <option>Master Degree</option>
                        </select>
                    </Col>
                    <Col md={3} className="form-text">
                        <h1 className='form-title'>Do you have a realestate experience</h1>
                        <select className='form-input'>
                            <option>Yes</option>
                            <option>No</option>
                        </select>
                    </Col>
                    <Col md={12} className="form-text">
                        <h1 className='form-title'><span>*</span>Major</h1>
                        <input type='text' className='form-input' />
                    </Col>
     
                </Row>
                <div style={{ width: '100%', display: 'flex' , justifyContent: 'flex-end'}}>
                    <button disabled className='form-btn'>Register</button>
                </div>

            </form>
        </Container>
    )
}

export default JoinOurTeam
