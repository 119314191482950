import React from 'react'
import "./Agent.css"

const Agent = ({ title, para}) => {
    return (
        <div className='agent'>
            <h1 className='agent-title'>{title}</h1>
            <p className='agent-desc'>{para}</p>
        </div>
    )
}

export default Agent
