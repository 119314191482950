import React from 'react';
import "./CategoryContent.css"
import { useState, useEffect } from 'react';
import { Product, Button } from '../../../../components/index';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const CategoryContent = ({ url, contentData, stylesData, apiVersion, options, translationsData }) => {
  const { id } = useParams();
  const categoriesData = contentData?.categories?.children || [];
  const [productsData, setProductsData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [noResults, setNoResults] = useState(false); 

  useEffect(() => {
    if (contentData) {
      const productsData = contentData?.products || [];
      setProductsData(productsData);
    } else {
      fetchDataByCategory(id);
    }
  }, [contentData, id]);

  const fetchDataByCategory = async (categoryId) => {
    const apiURL = `${url}${apiVersion}/products?category_id=${categoryId}&start=0&limit=20&category_children=true`;

    try {
      const response = await axios.get(apiURL, options);
      const data = response?.data?.data;
      setProductsData(data);
      setNoResults(data.length === 0);
    } catch (error) {
      console.log('Error fetching services:', error);
    }
  };

  const handleCategoryClick = (categoryId) => {
    if (categoryId === id) {
      return; 
    }
    setSelectedCategory(categoryId);
    fetchDataByCategory(categoryId);
  };

  return (
    <div className='coffeproductcontent'>
      <img id='coffecproductstop' src={url + stylesData.style_background_white} />
      <Container>
        <div className='coffee-btns'>
          <div 
              data-aos="fade-up"
              data-aos-duration="1500"
              onClick={() => fetchDataByCategory(id)} >
              <Button
                title={translationsData.all} 
                bg={"#000"} 
              />
            </div>
          {categoriesData.map((item, index) => (
            <div 
              data-aos="fade-up"
              data-aos-duration="1500"
              key={index} 
              onClick={() => handleCategoryClick(item.id)} >
              <Button
                title={item.name} 
                bg={item.background_color} 
              />
            </div>
          ))}
        </div>
        {
          selectedCategory ? (
            categoriesData.map((item, index) => (
              selectedCategory === item.id && (
                <div
                  key={index}
                  className='coffe-products-para'
                  dangerouslySetInnerHTML={{ __html: item.description }}
                ></div>
              )
            ))
          ) : (
            categoriesData.map((item, index) => (
              id === item.parent && (
                <div
                  key={index}
                  className='coffe-products-para'
                  dangerouslySetInnerHTML={{ __html: item.description }}
                ></div>
              )
            ))
          )
        }
         
        <div>
          { noResults ? (
            <div className="no-result" data-aos="fade-right" data-aos-duration="1500">No results</div>
          ) : (
            <Row className='justify-content-center'>
              {productsData.map((item, index) => (
                <Col 
                  lg={4} 
                  md={6} 
                  key={index}
                  data-aos="zoom-in"
                  data-aos-duration="1500">
                  <a href={`/product/${item.id}`}>
                    <Product title={item.name} bg={item.background_color} image={url + item.image} />
                  </a>
                </Col>
              ))}
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
};

export default CategoryContent;