import React from 'react'
import "./Herosection.css" 

const Herosection = ( { bg, title } ) => {
  return (
    <div className='hero-section'>
        <img src={bg} />
        <h1 className='animate__animated'>{title}</h1>
    </div>
  )
}

export default Herosection