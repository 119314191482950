import React from 'react'
import "./Quality.css"

const Quality = ({ url, contentData }) => {
  return (
    <div className='quality-about' 
      style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url(${url + contentData.about_us_3_image}) no-repeat`,
      }}
    >
        <p 
          dangerouslySetInnerHTML={{ __html: contentData.about_us_3_description }}
          data-aos="fade-left" 
          data-aos-offset="500" 
          data-aos-duration="1200">
        </p>
    </div>
  )
}

export default Quality