import React from 'react'
import "./OtherProducts.css"
import { Container, Row, Col } from 'react-bootstrap';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';


const OtherProducts = ({ contentData, url, stylesData }) => {

  const categoriesData = contentData.categories || [];

  return (
    <div className='otherproducts'>
         <img id="otherproduct-up" src={url + stylesData.style_background_white} />
         <Container className='otherproduct-content'>
            <div className='otherproducts-para' dangerouslySetInnerHTML={{ __html: contentData.products_description }}></div>
           
            <Row className='mt-5 justify-content-center'>
              {
                categoriesData.map((item, index) => {
                const galleryData = item.gallery || [];
                return (
                  <Col xl={5} sm={6} key={index} data-aos="zoom-out" data-aos-duration="1500">
                    <a href={`/category/${item.id}`}>
                      <div className='otherproduct'>
                        <div className='otherproduct-img'>
                          {
                            galleryData.length > 0 ? (
                              <Splide
                                options={{
                                  type: 'fade',
                                  rewind: true,
                                  arrows: false,
                                  pagination: false,
                                  autoplay: true,
                                }}
                              >
                                {galleryData.map((itemgal, indexgal) => (
                                  <SplideSlide key={indexgal}>
                                    <img src={url + itemgal.image} />
                                  </SplideSlide>
                                ))}
                              </Splide>
                            ) : (
                              <Splide
                              options={{
                                type: 'fade',
                                rewind: true,
                                arrows: false,
                                pagination: false,
                                autoplay: true,
                              }}
                              >
                                  <SplideSlide>
                                    <img src={require("../../../../assets/images/otherproduct1.jpg")} />
                                  </SplideSlide>
                                  <SplideSlide>
                                    <img src={require("../../../../assets/images/otherproduct2.jpg")} />
                                  </SplideSlide>
                              
                              </Splide>
                            )
                          }
                        </div>
                        <h1>{item.name}</h1>
                      </div>
                    </a>
                  </Col>
                );
              })}
            </Row>
         </Container>
    </div>
  )
}

export default OtherProducts