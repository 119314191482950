import React from 'react'
import "./Product.css"


const Product = ( {title, bg, image} ) => {
  return (
    <div style={{background: `${bg}`}} className='coffe-product'>
        <h1>{title}</h1>
        <div className='coffe-product-img'>
          <img src={image} />
        </div>
    </div>
  )
}

export default Product