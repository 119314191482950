import React from 'react';
import './Product.css';

const Product = ({ title, title2, image, bg, desc, specification, productData }) => {
  const hasProductData = productData && productData.length > 0;
  const opacity = hasProductData ? 1 : 0.5;

  return (
    <div className="product" style={{ backgroundColor: `${bg}`, opacity: `${opacity}` }}>
      <div className="product-title">
        <h1>{title}</h1>
        <p>{title2}</p>
      </div>
      <div className="product-img" data-aos="zoom-in" data-aos-duration="1800">
        <img src={image} alt="Product" />
      </div>
      {specification && specification.length >= 1 && (
        <div className="product-before">{desc}</div>
      )}
    </div>
  );
};

export default Product