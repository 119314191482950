import React from 'react'
import "./Button.css"

const Button = ( {title, bg} ) => {
  return (
    <button style={{background: `${bg}`}} className='filter-btn'>
        {title}
    </button>
  )
}

export default Button