import React from 'react'
import "./Footer.css"
import { Container } from 'react-bootstrap'

const Footer = ({ url, footerData, stylesData }) => {
  return (
    <div className='footer' style={{ background: `url(${url + stylesData.style_background_blue_big}) no-repeat`}}>
      <img id='logo2' src={url + footerData.footer_logo} />
        <Container>
          <div className='footer-content'>
            {/* <h1>{footerData.footer_title}</h1> */}
            <img src={require("../../assets/images/footernew.png")} className='footer-img' />
            <div className='footer-para'>
            <p  dangerouslySetInnerHTML={{ __html: footerData.footer_description }}></p>
            </div>
            <span>{footerData.footer_copyright}</span>
          </div>
        </Container>
    </div>
  )
}

export default Footer