import React, { useState, useEffect } from 'react';
import "./Products.css";
import { Col, Container, Row } from 'react-bootstrap';
import Product from '../Product/Product';

const Products = ({ contentData, url, translationsData, selectedCategoryId, apiVersion, options }) => {
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${url}${apiVersion}/products?category_id=${selectedCategoryId}&start=0&limit=20&category_children=true`, options);
        const data = await response.json();
        setProductData(data.data || []);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    if (selectedCategoryId) {
      fetchProducts();
    } else {
      // Set a default value for productData when no category is selected
      setProductData(contentData?.products || []);
    }
  }, [selectedCategoryId, url, apiVersion, contentData?.products]);

  return (
    <div className='products' id="products">
      <Container>
        <h1 className="products-section-title">{translationsData.related_products}</h1>
        <Row className='products-container'>
          {Array.isArray(productData) && productData.map((item, index) => (
            <Col key={index} xl={4} md={6}>
              <a href={`/product/${item.id}`}>
                <Product
                  productData={productData}
                  specification={item.specifications}
                  title={item.name}
                  title2={item.sub_title}
                  image={url + item.image}
                  bg={item.background_color}
                  desc={item.specifications?.map((specitem, index) => (
                    <div className='details-info-content' key={index}>
                      <h2>{specitem.specification.specification}</h2>
                      <h3>{specitem.value}</h3>
                    </div>
                  ))}
                />
              </a>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Products;